@import url('https://fonts.googleapis.com/css?family=Manrope:600');
input {
    height: 45px;
    padding: 10px 15px;
    margin-bottom: 15px;
}
button {
    outline: none;
    border: none;
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
}
.login__container,
.signup__container,
.verify,
.dashboard {
    flex: 1;
    width: 100%;
    min-height: 70%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.login__container,h2
{
    font-size: 1.5rem;
}

.h3
{
    font-size: 1rem;
    text-align: left;
    color: #868D95;
}

.login__form,
.verify__form,
.signup__form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.loginBtn,
.signupBtn,
.codeBtn {
    background-color: #004AAD;
    color: #fff;
    margin-bottom: 15px;
    border-radius: 0.25rem!important;
}
.signOutBtn {
    background-color: #c21010;
    color: #fff;
}
.link {
    cursor: pointer;
    color: #004AAD;
}
.code {
    width: 50%;
    text-align: center;
}
.verify__form {
    align-items: center;
}

.prefElement{
    background-color: #004AAD;
}


.login__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.input-field .MuiOutlinedInput-root {
    border-radius: 16px;
    font-size: 1rem;
    color: #000;
}

.input-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #004AAD;
}

.input-field .MuiInputLabel-root {
    font-size: 1rem;
    color: #868D95;
}

.loginBtn {
    background-color: #004AAD;
    color: white;
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    margin-top: 10px;
}

.error-message {
    color: red;
    font-size: 0.875rem;
}

.link {
    color: #004AAD;
    cursor: pointer;
    font-weight: bold;
}

@media screen and (max-width: 800px) {
    .login__container,
    .signup__container,
    .verify {
        padding: 0 30px;
    }
}

@media screen and (max-width: 480px) {

    .login__container,
.signup__container,
.verify,
.dashboard {
    flex: 1;
    width: 100%;
    min-height: 70%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login__container,h2
{
    font-size: 1rem;
    align-self: flex-start;
}

.login-image
{
    height: 15rem;
    display: flex;
    padding-right: 50px;
}

.h3
{
    font-size: 0.8rem;
    color: #868D95;
    align-self: flex-start;
}

    .login__form {
        height: 40px;
        padding: 10px 2px;
        margin-bottom: 10px;
    }

    .input-field .MuiOutlinedInput-root {
        border-radius: 16px;
        border: 0.5px solid #756EF3;
        height: 45px;
        font-size: 0.75rem;
        color: #000;
    }
    
    .input-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #004AAD;
    }
    
    .input-field .MuiInputLabel-root {
        font-size: 0.75rem;
        color: #868D95;
    }

    .loginBtn {
        padding: 12px;
        font-size: 14px;
        border-radius: 8px;
        width: 100%;
        max-width: 280px;
        margin-top: 8px;
    }

    
}