@import url('https://fonts.googleapis.com/css?family=Manrope:600');
/* Sidebar Menu Container */
.bm-menu {
  background: #373A47;
  padding: 2em 1.5em 0;
  font-size: 1.15em;
  color: #ffffff;
}

/* Sidebar Header */
.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.subtitle {
  color: #d1d1d1;
  font-size: 1rem;
  margin-bottom: 8px;
}

.retry-btn {
  background-color: #28a745;
  color: #ffffff;
  font-size: 0.95rem;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 50px;
  border: none;
  cursor: pointer;
}

/* Sidebar Links */
.menu-items {
  display: flex;
  flex-direction: column;
}

.nav-link {
  display: flex;
  align-items: center;
  color: #d1d1d1;
  font-size: 1.1rem;
  margin: 10px 0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ffffff;
}

.icon {
  margin-right: 10px;
  font-size: 1.45rem;
  color: #ffffff;
}

/* Footer Links */
.sidebar-footer {
  position: absolute;
  bottom: 0;
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #444;
}

.footer-link {
  display: flex;
  align-items: center;
  color: #ddddd8;
  margin-bottom: 10px;
  font-size: 1rem;
  text-decoration: none;
}

.footer-link .icon {
  margin-right: 8px;
  font-size: 2.2rem;
  color: #fdfdfd;
}

.footer-link .heart {
  margin-right: 8px;
  font-size: 2.2rem;
  color: #FF3D00;
}

.footer-link:hover {
  color: #ffffff;
}

/* Logout Styling */
.logout {
  color: #e74c3c;
  cursor: pointer;
}

/* Style for the gray background rectangle behind the text */
.text-background {
  background-color: #4B4E59; /* Couleur grise */
  color: #ffffff;
  padding: 5px 5px; /* Espace autour du texte */
  border-radius: 7px; /* Coins arrondis */
  font-size: 1rem;
  margin-left: 10px; /* Espace entre l'icône et le rectangle */
  display: inline-block;
}


.fixed-bottom {
  /* Positionnement des éléments en bas du menu */
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 2em;
}