@import url('https://fonts.googleapis.com/css?family=Manrope:600');
.uniform-size {
  font-size: 1.25rem; /* Taille du texte pour les deux éléments */
  font-weight: bold;
  padding: 15px 20px;
  background-color: #035AA6; /* Couleur de fond */
  color: white; /* Couleur du texte */
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
  line-height: 100%;
  width: 85%;
  height: 50px;
  text-transform: uppercase;
}

.pseudo-button {
  cursor: default;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, #035AA6 50%, #C4C4C4 50%);
  background-size: 200% 100%;
  animation: gradient-shift 3s ease infinite;
}

  .banner-container {
    width: 95%;
    max-width: 1200px; /* Limiter la largeur maximale pour les grands écrans */
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto; /* Centrer horizontalement */
    padding: 10px;
    border-radius: 5px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajuste l'image pour couvrir toute la bannière */
    border-radius: 5px; /* Garde les coins arrondis */
  }
  
  /* Pour les écrans plus petits comme les téléphones mobiles */
  @media (max-width: 768px) {
    .banner-container {
      height: 20px; /* Réduire la hauteur pour les petits écrans */
      padding: 5px; /* Réduire l'espacement */
    }
  
    .banner-image {
      height: 100%; /* Garder l'image responsive avec la nouvelle hauteur */
    }
  }
  
  /* Pour les très petits écrans */
  @media (max-width: 480px) {
    .banner-container {
      height: 80px; /* Réduire encore plus la hauteur */
      padding: 5px;
    }
  
    .banner-image {
      height: 100%;
    }
  }

  @keyframes gradient-shift {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  