ul.footer-list:before, ul.footer-list:after {
   content: "";
   flex: 1 1;
   border-bottom: 1px solid #000;
   margin: auto;
}

ul.footer-list{
   font-size: 1.5em;
   position: static;
   bottom: 0;
}

.style-none {
   text-decoration: none;
}

.banner-container {
   width: 95%;
   max-width: 1200px; /* Limiter la largeur maximale pour les grands écrans */
   position: relative;
   left: 0;
   right: 0;
   bottom: 0;
   margin: 0 auto; /* Centrer horizontalement */
   margin-top: 25em;
   padding: 10px;
   border-radius: 5px;
   height: 150px;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .banner-image {
   width: 100%;
   height: 100%;
   object-fit: cover; /* Ajuste l'image pour couvrir toute la bannière */
   border-radius: 5px; /* Garde les coins arrondis */
 }

 @media (max-width: 768px) {
   .banner-container {
     height: 10%; /* Réduire la hauteur pour les petits écrans */
     padding: 5px; /* Réduire l'espacement */
   }
 
   .banner-image {
     height: 100%; /* Garder l'image responsive avec la nouvelle hauteur */
   }
 }
 
 /* Pour les très petits écrans */
 @media (max-width: 480px) {
   .banner-container {
     height: 10%; /* Réduire encore plus la hauteur */
     padding: 5px;
   }
 
   .banner-image {
     height: 70px;
   }
 }